/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
	    // BEGIN COOKIE FOR SITE-WIDE WELCOME OVERLAY
        if (Cookies.get('noShowWelcome')) {
	    	$('#intro-overlay').hide();  
        } else {
	    	Cookies.set('noShowWelcome', true);    
        }
        $("#intro-overlay").click(function() {
            $("#intro-overlay").fadeOut(1000);
            return false;    
        });
        // END COOKIE FOR SITE-WIDE WELCOME OVERLAY
        
		// BEGIN HIGHLIGHT PROJECTS NAV ITEM WHEN ACTIVE        
        if($('body').hasClass('single-projects')) {
	    	$('.nav .menu-item-has-children').addClass('active'); 
	    }
		// END HIGHLIGHT PROJECTS NAV ITEM WHEN ACTIVE	     
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired 
                
                
        // BEGIN SCRIPTS FOR MOBILE MENU TRIGGER
        $('header.banner .menu-trigger').click(function(){
            $('header.banner .nav').toggleClass('active');
        });
        
        $('header.banner .nav li.menu-item-projects > a').click(function(){
            dropDownNav();
            return false;
        });
        // END SCRIPTS FOR MOBILE MENU TRIGGER 
        
        
		// BEGIN SCRIPTS FOR DESKTOP PROJECTS DROPDOWN MENU        
        function dropDownNav() {
            if($('header.banner .nav li.menu-item-projects').hasClass('open')) {
                $('header.banner .nav li.menu-item-projects').addClass('closing');
                setTimeout(function(){
                    $('header.banner .nav li.menu-item-projects').removeClass('closing');
                    $('header.banner .nav li.menu-item-projects').removeClass('open');
                },451);
            } else {
                $('header.banner .nav li.menu-item-projects').addClass('opening');
                setTimeout(function(){
                    $('header.banner .nav li.menu-item-projects').removeClass('opening');
                    $('header.banner .nav li.menu-item-projects').addClass('open');
                },200);
            }
        }

        $("body").click(function(){
            if($('header.banner .nav li.menu-item-projects').hasClass('open')) {
                $('header.banner .nav li.menu-item-projects').addClass('closing');
                setTimeout(function(){
                    $('header.banner .nav li.menu-item-projects').removeClass('closing');
                    $('header.banner .nav li.menu-item-projects').removeClass('open');
                },451);
            }
        });
  		// END SCRIPTS FOR DESKTOP PROJECTS DROPDOWN MENU

        // BEGIN SCRIPTS FOR TOUCH CAROUSEL
        var mobile = window.matchMedia( "(max-width: 1024px)" );
        if(mobile.matches){
            $("#carousel-gallery").addClass('is-mobile');
        }

        $("#carousel-gallery").waitForImages(function() {
            console.log('done with wait for images, now load touchCarousel');

            $("#carousel-gallery").touchCarousel({
                //itemsPerPage: 1,                
                scrollbar: false,
                scrollbarAutoHide: true,
                //scrollbarTheme: "dark",             
                pagingNav: false,
                snapToItems: false,
                scrollToLast: true,
                loopItems: false,
    
                directionNav: true,            // Direction (arrow) navigation (true or false).
                directionNavAutoHide: true,
    
                keyboardNav: true
            });                                      

            $(".loading").fadeOut(500);
            $("#carousel-gallery").addClass('active');
        });
        // END SCRIPTS FOR TOUCH CAROUSEL
          

        $('.reveal').click(function(){
           $('.read-more').toggleClass('open');
           $(this).toggleClass('open');
           return false;
        });
		
		
		// BEGIN SCRIPTS READ MORE REVEALER
		$('.main-content.large-device .summary-wrapper').readmore({
			collapsedHeight: 460,
			speed: 500,
			heightMargin: 30,
			moreLink: '<a href="#" class="reveal"></a>',
			lessLink: '<a href="#" class="reveal open"></a>',
			blockCSS: 'display: inline-block;'
		});	
		// END SCRIPTS READ MORE REVEALER

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS      
        
        // BEGIN SWIPER SCRIPTS ON HOME PAGE
        var swiper = new Swiper('.swiper-container', {
	        preloadImages: false,
            updateOnImagesReady: false,
	        lazyLoading: true,
            lazyLoadingInPrevNext: true,
	        //pagination: '.swiper-pagination',
	        //paginationClickable: true,
	        nextButton: '.swiper-button-next',
	        prevButton: '.swiper-button-prev',
	        autoplay: false,
	        loop: true,
	        speed: 750,
            keyboardControl: true,
            onLazyImageReady: function (s) {
              if (!s.params.autoplay) {
                console.log('loaded');
                s.params.autoplay = 5000;
                s.startAutoplay();
              }
            }
	    });
        // END SWIPER SCRIPTS ON HOME PAGE             

        // END CONVERT IMAGES FROM DATA-SRC INTO BACKGROUND-IMAGE SO WE CAN CONTROL THEM IN JS
      }
    },

    // Project page
    'category': {
      init: function() {
        // JavaScript to be fired on the home page    
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // BEGIN SCRIPTS LOAD AND FADE IN IMAGE ON PROJECT THUMBNAIL PAGE
        $(".project-thumbnail img").each(function() {
            var src = $(this).attr('data-src');
            console.log(src);
            $(this).attr('src', src).removeAttr('data-src');

            $(this).load(function() {
                console.log('loaded');
                $(this).parent().addClass('loaded');
                $(this).fadeIn('slow');
            });
        });
        // END SCRIPTS LOAD AND FADE IN IMAGE ON PROJECT THUMBNAIL PAGE
        
      }
    },

    // Contact page
    'contact': {
      init: function() {
        // JavaScript to be fired on the contact page
		function initialize() {
            var mapCanvas = document.getElementById('contact-map');
            var mapOptions = {
                center: new google.maps.LatLng(-33.91696, 18.4174),
                zoom: 14,
                mapTypeId: google.maps.MapTypeId.ROADMAP,

                mapTypeControl: false,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                    position: google.maps.ControlPosition.LEFT_TOP
                },

                panControl: true,
                panControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                },

                streetViewControl: true, 
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                },

                zoomControl: true,
                zoomControlOptions: {
                    style: google.maps.ZoomControlStyle.SMALL,
                    position: google.maps.ControlPosition.LEFT_TOP
                },

                styles: [
                    {
                        "featureType": "administrative",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#444444"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.natural",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#b0dbca"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 45
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#c9cdd4"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#a8aeb8"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#222222"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#46bcec"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#77c9d5"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#ff0000"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#5e5e5e"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    }
                ]
            };
            var map = new google.maps.Map(mapCanvas, mapOptions);
            // Let's also add a marker while we're at it
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(-33.91696, 18.4174),
                map: map,
                animation: google.maps.Animation.DROP,
                title: 'Leon Saven Design Studio'
            });

            var contentString = 
                '<div id="mapPopup">'+
                '<div class="image"></div>'+
                '<div class="content"><h1>Leon Saven Design</h1>'+
                '<p>28 Hudson Street<br>Cape Town<br>South Africa</p></div>' +
                '</div>';

            var infowindow = new google.maps.InfoWindow({ 
                content: contentString
        	});

            infowindow.open(map, marker);

            marker.addListener('click', function() {
                infowindow.open(map, marker);
            });
          }
          google.maps.event.addDomListener(window, 'load', initialize);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
